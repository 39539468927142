<template>
    <v-dialog v-model="dialog" max-width="900">
        <v-card>
            <v-card-title class="headline pb-2">
                Bestandsverlauf
                <v-chip small label class="ml-2" color="grey lighten-3">
                    {{ sku }}
                </v-chip>
                <v-spacer></v-spacer>
                <v-btn
                    small
                    color="primary"
                    class="mr-2"
                    @click="exportToCsv"
                    :loading="exporting"
                >
                    <v-icon left small>mdi-download</v-icon>
                    CSV Export
                </v-btn>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <!-- Filters -->
                <v-row class="mb-4">
                    <v-col cols="12" sm="4">
                        <v-select
                            v-model="selectedType"
                            :items="logTypes"
                            label="Typ"
                            dense
                            outlined
                            clearable
                            hide-details
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-menu
                            v-model="dateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateRangeText"
                                    label="Zeitraum"
                                    dense
                                    outlined
                                    readonly
                                    hide-details
                                    v-bind="attrs"
                                    v-on="on"
                                    clearable
                                    @click:clear="clearDateRange"
                                >
                                    <template v-slot:append>
                                        <v-icon>mdi-calendar</v-icon>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dateRange"
                                range
                                no-title
                                scrollable
                            >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dateMenu = false">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field
                            v-model="search"
                            label="Suchen..."
                            dense
                            outlined
                            hide-details
                            clearable
                            prepend-inner-icon="mdi-magnify"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <!-- Logs Table -->
                <v-data-table
                    :headers="headers"
                    :items="filteredLogs"
                    :loading="loading"
                    :search="search"
                    :items-per-page="10"
                    :sort-by="['date']"
                    :sort-desc="[true]"
                    class="elevation-1"
                >
                    <!-- Type Column -->
                    <template v-slot:item.type="{ item }">
                        <v-chip
                            x-small
                            :color="getTypeColor(item.type)"
                            dark
                            label
                        >
                            {{ getTypeLabel(item.type) }}
                        </v-chip>
                    </template>

                    <!-- Quantity Column -->
                    <template v-slot:item.quantity="{ item }">
                        <span :class="getQuantityClass(item)">
                            {{ getQuantityPrefix(item) }}{{ item.quantity }}
                        </span>
                    </template>

                    <!-- Date Column -->
                    <template v-slot:item.date="{ item }">
                        <span class="caption">{{ formatDate(item.date) }}</span>
                    </template>

                    <!-- Order Column -->
                    <template v-slot:item.orderId="{ item }">
                        <v-chip
                            v-if="item.orderId"
                            x-small
                            label
                            color="grey lighten-3"
                            class="mono-text"
                        >
                            {{ item.orderId }}
                        </v-chip>
                        <span v-else>-</span>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'LogsDialog',

    props: {
        show: {
            type: Boolean,
            default: false
        },
        sku: {
            type: String,
            required: true
        },
        logs: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            dialog: false,
            loading: false,
            search: '',
            selectedType: null,
            dateRange: [],
            dateMenu: false,
            headers: [
                { text: 'Typ', value: 'type', width: '150' },
                { text: 'Menge', value: 'quantity', width: '120' },
                { text: 'Datum', value: 'date', width: '200' },
                { text: 'Bestellung', value: 'orderId' }
            ],
            logTypes: [
                { text: 'Bestand erhöht', value: 'STOCK_INCREASE' },
                { text: 'Bestand reduziert', value: 'STOCK_DECREASE' },
                { text: 'Bestellung', value: 'ORDER' },
                { text: 'Korrektur', value: 'CORRECTION' }
            ],
            exporting: false,
        }
    },

    computed: {
        dateRangeText() {
            if (!this.dateRange.length) return '';
            const [start, end] = this.dateRange;
            if (!end) return this.formatDate(start);
            return `${this.formatDate(start)} - ${this.formatDate(end)}`;
        },

        filteredLogs() {
            let filtered = [...this.logs];

            // Filter by type
            if (this.selectedType) {
                filtered = filtered.filter(log => log.type === this.selectedType);
            }

            // Filter by date range
            if (this.dateRange.length === 2) {
                const [start, end] = this.dateRange;
                filtered = filtered.filter(log => {
                    const logDate = new Date(log.date);
                    return logDate >= new Date(start) && logDate <= new Date(end);
                });
            }

            return filtered;
        }
    },

    watch: {
        show(val) {
            this.dialog = val;
        },
        dialog(val) {
            if (!val) this.$emit('close');
        }
    },

    methods: {
        getTypeColor(type) {
            const colors = {
                STOCK_INCREASE: 'success',
                STOCK_DECREASE: 'error',
                ORDER: 'primary',
                CORRECTION: 'warning'
            };
            return colors[type] || 'grey';
        },

        getTypeLabel(type) {
            const labels = {
                STOCK_INCREASE: 'Erhöhung',
                STOCK_DECREASE: 'Reduzierung',
                ORDER: 'Bestellung',
                CORRECTION: 'Korrektur'
            };
            return labels[type] || type;
        },

        getQuantityClass(item) {
            return {
                'success--text': item.type === 'STOCK_INCREASE',
                'error--text': item.type === 'STOCK_DECREASE',
                'mono-text': true
            };
        },

        getQuantityPrefix(item) {
            return item.type === 'STOCK_DECREASE' ? '-' : '+';
        },

        formatDate(date) {
            return new Date(date).toLocaleString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        },

        clearDateRange() {
            this.dateRange = [];
        },

        exportToCsv() {
            this.exporting = true;
            try {
                // Format the data for CSV
                const csvData = this.filteredLogs.map(log => ({
                    Typ: this.getTypeLabel(log.type),
                    Menge: (log.type === 'STOCK_DECREASE' ? '-' : '+') + log.quantity,
                    Datum: this.formatDate(log.date),
                    Bestellung: log.orderId || '-'
                }));

                // Create CSV content
                const headers = ['Typ', 'Menge', 'Datum', 'Bestellung'];
                const csvContent = [
                    headers.join(';'),
                    ...csvData.map(row => headers.map(header => row[header]).join(';'))
                ].join('\n');

                // Create and trigger download
                const blob = new Blob(['\ufeff' + csvContent], { 
                    type: 'text/csv;charset=utf-8;' 
                });
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                
                link.setAttribute('href', url);
                link.setAttribute('download', `bestandsverlauf_${this.sku}_${this.formatFileDate(new Date())}.csv`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error('Error exporting CSV:', error);
            } finally {
                this.exporting = false;
            }
        },

        formatFileDate(date) {
            return date.toISOString().split('T')[0];
        }
    }
}
</script>

<style scoped>
.mono-text {
    font-family: 'Roboto Mono', monospace;
    font-size: 0.875rem;
}

.v-chip {
    font-weight: 500;
}

.v-btn.small-btn {
    text-transform: none;
}
</style>