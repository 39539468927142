<template>
    <v-dialog
        :value="show"
        max-width="700"
        @click:outside="$emit('close')"
    >
        <v-card>
            <v-card-title class="headline pb-2">
                Bestand mehrerer Kartonagen anpassen
                <v-chip
                    small
                    label
                    class="ml-2"
                    color="grey lighten-3"
                >
                    {{ selectedItems.length }} ausgewählt
                </v-chip>
            </v-card-title>

            <v-card-text>
                <v-row align="center" class="mx-0">
                    <v-col cols="12">
                        <v-text-field
                            v-model="changeAmount"
                            type="number"
                            label="Änderungsbetrag"
                            outlined
                            dense
                            hide-details
                            class="mb-4"
                            @keyup.enter="handleSubmit"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-divider class="my-4"></v-divider>

                <div class="selected-items-list">
                    <div 
                        v-for="item in selectedItems" 
                        :key="item._id"
                        class="selected-item d-flex align-center py-2"
                    >
                        <v-avatar 
                            size="32" 
                            rounded 
                            class="mr-2 grey lighten-4"
                        >
                            <v-img
                                v-if="item.images && item.images.length"
                                :src="item.images[0]"
                                contain
                            ></v-img>
                            <v-icon v-else color="grey darken-1">mdi-package-variant</v-icon>
                        </v-avatar>
                        <div class="flex-grow-1">
                            <div class="subtitle-2">{{ item.title }}</div>
                            <div class="caption grey--text">{{ item.sku }}</div>
                        </div>
                        <div class="text-right">
                            <div class="subtitle-2">{{ item.stock }}</div>
                            <div class="caption" :class="getNewStockClass(item)">
                                {{ getNewStock(item) }}
                            </div>
                        </div>
                    </div>
                </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="$emit('close')"
                >
                    Abbrechen
                </v-btn>
                <v-btn
                    color="primary"
                    :disabled="!isValidChange"
                    @click="handleSubmit"
                >
                    Speichern
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'BulkStockEditDialog',
    
    props: {
        show: {
            type: Boolean,
            default: false
        },
        selectedItems: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            changeAmount: 0
        }
    },

    computed: {
        isValidChange() {
            return this.changeAmount !== 0 && !isNaN(this.changeAmount);
        }
    },

    methods: {
        getNewStock(item) {
            const newStock = item.stock + Number(this.changeAmount);
            return newStock >= 0 ? newStock : 0;
        },

        getNewStockClass(item) {
            const newStock = this.getNewStock(item);
            if (newStock <= 10) return 'error--text';
            if (newStock <= 50) return 'warning--text';
            return 'success--text';
        },

        handleSubmit() {
            if (!this.isValidChange) return;
            
            const updates = this.selectedItems.map(item => ({
                sku: item.sku,
                stock: this.getNewStock(item)
            }));

            this.$emit('submit', updates);
        }
    }
}
</script>

<style scoped>
.selected-items-list {
    max-height: 300px;
    overflow-y: auto;
}

.selected-item {
    border-bottom: 1px solid rgba(0,0,0,0.08);
}

.selected-item:last-child {
    border-bottom: none;
}
</style> 