<template>
    <v-dialog
        :value="show"
        max-width="800"
        @click:outside="$emit('close')"
    >
        <v-card>
            <v-card-title class="headline pb-2">
                Kartonage bearbeiten
                <v-chip
                    small
                    label
                    class="ml-2"
                    color="grey lighten-3"
                >
                    {{ editedItem.sku }}
                </v-chip>
            </v-card-title>

            <v-tabs v-model="tab" background-color="grey lighten-4">
                <v-tab>
                    <v-icon left small>mdi-link-variant</v-icon>
                    Zuweisung
                </v-tab>
                <v-tab>
                    <v-icon left small>mdi-pencil</v-icon>
                    Informationen
                </v-tab>
                <v-tab>
                    <v-icon left small>mdi-image</v-icon>
                    Bilder
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <!-- SKU Assignment Tab -->
                <v-tab-item>
                    <v-card flat>
                        <v-card-text class="pt-4">
                            <div class="d-flex flex-column mb-4">
                                <div class="d-flex align-center mb-2">
                                    <div class="flex-grow-1">
                                        <v-autocomplete
                                            v-model="selectedSkus"
                                            :items="uniqueSkus"
                                            label="SKUs zuweisen"
                                            multiple
                                            chips
                                            small-chips
                                            deletable-chips
                                            outlined
                                            dense
                                            hide-details
                                            item-text="sku"
                                            return-object
                                            @change="handleSkuSelection"
                                        >
                                            <template v-slot:selection="{ item, index, selected }">
                                                <v-chip
                                                    v-if="index === 0"
                                                    small
                                                    label
                                                    class="mr-1"
                                                >
                                                    {{ item.sku }}
                                                    <span class="caption grey--text ml-1">
                                                        {{ item.category }}
                                                    </span>
                                                </v-chip>
                                                <span
                                                    v-if="index === 0 && selectedSkus.length > 1"
                                                    class="grey--text caption ml-2"
                                                >
                                                    (+{{ selectedSkus.length - 1 }} weitere)
                                                </span>
                                            </template>
                                        </v-autocomplete>
                                    </div>

                                    <v-menu
                                        offset-y
                                        :close-on-content-click="false"
                                        left
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                color="primary"
                                                text
                                                v-bind="attrs"
                                                v-on="on"
                                                class="ml-2"
                                                min-width="0"
                                            >
                                                <v-icon>mdi-shape-outline</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-card min-width="300">
                                            <v-card-title class="subtitle-1">Kategorie hinzufügen</v-card-title>
                                            <v-card-text>
                                                <v-list dense>
                                                    <v-list-item
                                                        v-for="category in availableCategories"
                                                        :key="category"
                                                        @click="addSkusByCategory(category)"
                                                    >
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{ category }}
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle class="caption">
                                                                {{ getSkuCountForCategory(category) }} SKUs verfügbar
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-btn
                                                                small
                                                                text
                                                                color="primary"
                                                            >
                                                                Hinzufügen
                                                            </v-btn>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </v-list>
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </div>

                                <v-data-table
                                    :headers="skuHeaders"
                                    :items="selectedSkus"
                                    dense
                                    class="elevation-1"
                                >
                                    <template v-slot:item.sku="{ item }">
                                        <span class="font-weight-medium">{{ item.sku }}</span>
                                    </template>
                                    <template v-slot:item.category="{ item }">
                                        <v-chip
                                            x-small
                                            label
                                            color="grey lighten-3"
                                        >
                                            {{ item.category }}
                                        </v-chip>
                                    </template>
                                    <template v-slot:item.actions="{ item }">
                                        <v-btn
                                            icon
                                            x-small
                                            @click="removeSku(item)"
                                            color="error"
                                        >
                                            <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <!-- Information Tab -->
                <v-tab-item>
                    <v-card flat>
                        <v-card-text class="pt-4">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="editedItem.title"
                                        label="Bezeichnung"
                                        outlined
                                        dense
                                        hide-details
                                        class="mb-4"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="editedItem.sku"
                                        label="SKU"
                                        outlined
                                        dense
                                        hide-details
                                        class="mb-4"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        v-model.number="editedItem.lengthCm"
                                        label="Länge"
                                        type="number"
                                        suffix="cm"
                                        outlined
                                        dense
                                        hide-details
                                        class="mb-4"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        v-model.number="editedItem.widthCm"
                                        label="Breite"
                                        type="number"
                                        suffix="cm"
                                        outlined
                                        dense
                                        hide-details
                                        class="mb-4"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        v-model.number="editedItem.heightCm"
                                        label="Höhe"
                                        type="number"
                                        suffix="cm"
                                        outlined
                                        dense
                                        hide-details
                                        class="mb-4"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model.number="editedItem.weight"
                                        label="Gewicht"
                                        type="number"
                                        suffix="g"
                                        outlined
                                        dense
                                        hide-details
                                        class="mb-4"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model.number="editedItem.pricePerUnit"
                                        label="Preis pro Stück"
                                        type="number"
                                        prefix="€"
                                        outlined
                                        dense
                                        hide-details
                                        class="mb-4"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-textarea
                                v-model="editedItem.description"
                                label="Beschreibung"
                                outlined
                                dense
                                hide-details
                                rows="3"
                                class="mb-4"
                            ></v-textarea>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <!-- Images Tab -->
                <v-tab-item>
                    <v-card flat>
                        <v-card-text class="pt-4">
                            <v-row>
                                <v-col cols="12">
                                    <v-file-input
                                        v-model="newImages"
                                        label="Bilder hochladen"
                                        multiple
                                        accept="image/*"
                                        outlined
                                        dense
                                        hide-details
                                        show-size
                                        counter
                                        class="mb-4"
                                    >
                                        <template v-slot:selection="{ index, text }">
                                            <v-chip
                                                v-if="index < 2"
                                                small
                                                label
                                                class="mr-1"
                                            >
                                                {{ text }}
                                            </v-chip>
                                            <span
                                                v-else-if="index === 2"
                                                class="grey--text caption"
                                            >
                                                (+{{ newImages.length - 2 }} weitere)
                                            </span>
                                        </template>
                                    </v-file-input>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col
                                    v-for="(image, i) in editedItem.images"
                                    :key="i"
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                    <v-card outlined>
                                        <v-img
                                            :src="image"
                                            aspect-ratio="1"
                                            contain
                                        ></v-img>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                icon
                                                small
                                                color="error"
                                                @click="removeImage(i)"
                                            >
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="$emit('close')"
                >
                    Abbrechen
                </v-btn>
                <v-btn
                    color="primary"
                    :loading="saving"
                    @click="handleSubmit"
                >
                    Speichern
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import PackagingService from '@/services/PackagingService';

export default {
    props: {
        show: {
            type: Boolean,
            required: true
        },
        item: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            tab: 0,
            uniqueSkus: [],
            editedItem: {},
            selectedSkus: [],
            newImages: [],
            saving: false,
            skuHeaders: [
                { text: 'SKU', value: 'sku' },
                { text: 'Kategorie', value: 'category' },
                { text: 'Aktionen', value: 'actions', sortable: false, width: '100' }
            ]
        }
    },

    computed: {
        debugSelectedSkus() {
            console.log('Selected SKUs:', this.selectedSkus);
            return this.selectedSkus;
        },
        availableCategories() {
            // Get unique categories from uniqueSkus
            return [...new Set(this.uniqueSkus.map(sku => sku.category))].sort();
        }
    },

    watch: {
        show(val) {
            if (val) {
                this.editedItem = { ...this.item };
                // Initialize with empty array if no linkedSkus
                this.selectedSkus = [];
                
                // If there are linkedSkus, convert them to proper objects
                if (this.item.linkedSkus && this.item.linkedSkus.length) {
                    this.selectedSkus = this.item.linkedSkus.map(skuString => {
                        const matchingSku = this.uniqueSkus.find(u => u.sku === skuString);
                        return matchingSku || { sku: skuString, category: 'Unbekannt' };
                    });
                }
            }
        }
    },

    methods: {
        async getUniqueSkus() {
            try {
                const response = await PackagingService.getUniqueSkus();
                if (response.status === 200) {
                    // Ensure we're storing objects
                    this.uniqueSkus = response.data.map(sku => ({
                        sku: typeof sku === 'string' ? sku : sku.sku,
                        category: typeof sku === 'string' ? 'Unbekannt' : sku.category
                    }));
                }
            } catch (error) {
                console.error('Error fetching SKUs:', error);
            }
        },

        handleSkuSelection(selected) {
            // Ensure we're working with objects
            this.selectedSkus = selected.map(item => {
                if (typeof item === 'string') {
                    const matchingSku = this.uniqueSkus.find(u => u.sku === item);
                    return matchingSku || { sku: item, category: 'Unbekannt' };
                }
                return item;
            });
        },

        removeSku(skuItem) {
            const index = this.selectedSkus.findIndex(item => 
                (typeof item === 'string' ? item : item.sku) === 
                (typeof skuItem === 'string' ? skuItem : skuItem.sku)
            );
            if (index > -1) {
                this.selectedSkus.splice(index, 1);
            }
        },

        removeImage(index) {
            this.editedItem.images.splice(index, 1);
        },

        async handleSubmit() {
            this.saving = true;
            try {
                const updatedItem = {
                    ...this.item,
                    linkedSkus: this.selectedSkus.map(item => {
                        if (typeof item === 'string') return item;
                        
                        if (item.sku && typeof item.sku === 'object') {
                            return item.sku.sku;
                        }
                        return item.sku;
                    })
                };

                if (this.newImages.length) {
                    // Handle image upload logic
                }

                this.$emit('submit', updatedItem);
            } catch (error) {
                console.error('Error saving packaging:', error);
            } finally {
                this.saving = false;
            }
        },

        getSkuCountForCategory(category) {
            return this.uniqueSkus.filter(sku => sku.category === category).length;
        },

        addSkusByCategory(category) {
            // Get all SKUs for this category
            const skusToAdd = this.uniqueSkus.filter(sku => sku.category === category);
            
            // Add only SKUs that aren't already selected
            const existingSkus = new Set(this.selectedSkus.map(sku => sku.sku));
            const newSkus = skusToAdd.filter(sku => !existingSkus.has(sku.sku));
            
            if (newSkus.length > 0) {
                this.selectedSkus = [...this.selectedSkus, ...newSkus];
            }
        }
    },

    created() {
        this.getUniqueSkus();
    }
}
</script>

<style scoped>
.v-tab {
    text-transform: none;
    letter-spacing: 0;
}

.v-card__title {
    font-size: 1.25rem;
}

.v-menu {
    display: inline-block;
}
</style>